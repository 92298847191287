export function formatStringDate(dateString) {
    const date = new Date(dateString);
    return formatDate(date);
}
export function formatDateISO(value) {
    let date = value;
    if (typeof date === 'string') {
        date = new Date(date);
    }
    return date.toISOString();
}
export function formatDate(value) {
    let date = value;
    if (typeof date === 'string') {
        date = new Date(date);
    }
    return new Intl.DateTimeFormat(undefined, {
        day: 'numeric',
        month: 'short',
        year: 'numeric',
    }).format(date);
}
export function formatDateLong(value, useShortMonthName) {
    let date = value;
    if (typeof date === 'string') {
        date = new Date(date);
    }
    return new Intl.DateTimeFormat(undefined, {
        day: 'numeric',
        month: useShortMonthName ? 'short' : 'long',
        year: 'numeric',
    }).format(date);
}
export function formatDateShort(value) {
    let date = value;
    if (typeof date === 'string') {
        date = new Date(date);
    }
    return new Intl.DateTimeFormat(undefined, {
        day: 'numeric',
        month: '2-digit',
        year: '2-digit',
    }).format(date);
}
